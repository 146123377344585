import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllAffiliates,
  getAllUsers,
  getAllCms,
  getUser,
  getAffiliateById,
  getCmsByPageId,
  getCountries,
  getTenantCountries,
  getRestrictedItems,
  getUnRestrictedItems,
  addRestrictedItems,
  deleteRestrictedItems,
  getRestrictedCountries,
  getUnRestrictedCountries,
  addRestrictedCountries,
  deleteRestrictedCountries,
  updateSuperAdminCMS,
  getGlobalRegistration,
  updateGlobalRegistration,
  getUserDocument,
  verifyUserDocument,
  superAdminViewToggleStatus,
  getCMSDynamicKeys,
  deleteCMSLanguage,
  updateKycMethod,
  getAllReviews,
  addReview,
  updateReview,
  getWalletData,
  getKycLabels,
  updateKycLabels,
  tenantViewToggleStatus,
  updateCountryStatus,
  getTags
} from '../../utils/apiCalls'

import {
  getAllAffiliatesStart,
  getAllAffiliatesSuccess,
  getAllAffiliatesFailure,
  getAllCmsStart,
  getAllCmsSuccess,
  getAllCmsFailure,
  getAllUsersStart,
  getAllUsersSuccess,
  getAllUsersFailure,
  getUserStart,
  getUserSuccess,
  getUserFailure,
  getAffiliateByIdStart,
  getAffiliateByIdSuccess,
  getAffiliateByIdFailure,
  getCmsByPageIdStart,
  getCmsByPageIdSuccess,
  getCmsByPageIdFailure,
  getCountriesStart,
  getCountriesSuccess,
  getCountriesFailure,
  getTenantCountriesStart,
  getTenantCountriesSuccess,
  getTenantCountriesFailure,
  getRestrictedItemsStart,
  getRestrictedItemsSuccess,
  getRestrictedItemsFailure,
  getUnRestrictedItemsStart,
  getUnRestrictedItemsSuccess,
  getUnRestrictedItemsFailure,
  addRestrictedItemsStart,
  addRestrictedItemsComplete,
  deleteRestrictedItemsStart,
  deleteRestrictedItemsComplete,
  resetRestrictedItemsStart,
  resetRestrictedItemsComplete,
  getRestrictedCountriesStart,
  getRestrictedCountriesSuccess,
  getRestrictedCountriesFailure,
  getUnRestrictedCountriesStart,
  getUnRestrictedCountriesSuccess,
  getUnRestrictedCountriesFailure,
  addRestrictedCountriesStart,
  addRestrictedCountriesComplete,
  deleteRestrictedCountriesStart,
  deleteRestrictedCountriesComplete,
  resetRestrictedCountriesStart,
  resetRestrictedCountriesComplete,
  updateSuperAdminCMSStart,
  updateSuperAdminCMSComplete,
  getGlobalRegistrationStart,
  updateGlobalRegistrationStart,
  getGlobalRegistrationCompleted,
  getGlobalRegistrationFailed,
  updateGlobalRegistrationFailed,
  updateGlobalRegistrationCompleted,
  getSAUserDocumentStart,
  getUserDocumentSuccess,
  getUserDocumentFailure,
  verifyUserDocumentStart,
  verifyUserDocumentComplete,
  updateSACMSStatusStart,
  updateSACMSStatusComplete,
  getCMSDynamicKeysStart,
  getCMSDynamicKeysSuccess,
  getCMSDynamicKeysFailure,
  deleteCMSLanguageStart,
  deleteCMSLanguageComplete,
  updateKycMethodStart,
  updateKycMethodComplete,
  updateCountryStatusStart,
  updateCountryStatusComplete,
  addReviewFailure,
  addReviewSuccess,
  addReviewStart,
  getReviewByIdFailure,
  getReviewByIdSuccess,
  getReviewByIdStart,
  updateReviewFailure,
  updateReviewSuccess,
  updateReviewStart,
  getAllReviewFailure,
  getAllReviewSuccess,
  getAllReviewStart,
  getWalletDataStart,
  getWalletDataComplete,
  getWalletDataFailure,
  getKycLabelsStart,
  getKycLabelsComplete,
  getKycLabelsFailure,
  updateKycLabelsStart,
  updateKycLabelsComplete,
  updateKycLabelsFailure,
  setBufferImage,
  updateTenantUserDetail,
  getTagsDataStart,
  getTagsDataComplete
} from '../redux-slices/fetchData'
import { toast } from '../../components/Toast'
import { SuperAdminRoutes } from '../../routes'
import { getCmsDetailsStart, getUserDocumentStart } from '../redux-slices/tenantsFetchData'
import { checkIsTenant } from '../../utils/constants'

export default function * dataWatcher () {
  yield takeLatest(getAllAffiliatesStart.type, getAllAffiliatesWorker)
  yield takeLatest(getAllCmsStart.type, cmsWorker)
  yield takeLatest(getAllUsersStart.type, getAllUsersWorker)
  yield takeLatest(getUserStart.type, getUserWorker)
  yield takeLatest(getAffiliateByIdStart.type, getAffiliateByIdWorker)
  yield takeLatest(getCmsByPageIdStart.type, getCmsByPageIdWorker)
  yield takeLatest(getCountriesStart.type, getCountriesWorker)
  yield takeLatest(getTenantCountriesStart.type, getTenantCountriesWorker)
  yield takeLatest(getRestrictedItemsStart.type, getRestrictedItemsWorker)
  yield takeLatest(getUnRestrictedItemsStart.type, getUnRestrictedItemsWorker)
  yield takeLatest(addRestrictedItemsStart.type, addRestrictedItemsWorker)
  yield takeLatest(deleteRestrictedItemsStart.type, deleteRestrictedItemsWorker)
  yield takeLatest(resetRestrictedItemsStart.type, resetRestrictedItemsWorker)
  yield takeLatest(getRestrictedCountriesStart.type, getRestrictedCountriesWorker)
  yield takeLatest(getUnRestrictedCountriesStart.type, getUnRestrictedCountriesWorker)
  yield takeLatest(addRestrictedCountriesStart.type, addRestrictedCountriesWorker)
  yield takeLatest(deleteRestrictedCountriesStart.type, deleteRestrictedCountriesWorker)
  yield takeLatest(resetRestrictedCountriesStart.type, resetRestrictedCountriesWorker)
  yield takeLatest(updateSuperAdminCMSStart.type, updateSuperAdminCMSWorker)
  yield takeLatest(getGlobalRegistrationStart.type, getGlobalRegistartionWorker)
  yield takeLatest(updateGlobalRegistrationStart.type, updateGlobalRegistartionWorker)
  yield takeLatest(getSAUserDocumentStart.type, getUserDocumentWorker)
  yield takeLatest(verifyUserDocumentStart.type, verifyUserDocumentWorker)
  yield takeLatest(updateSACMSStatusStart.type, updateSACMSStatusWorker)
  yield takeLatest(getCMSDynamicKeysStart.type, getCMSDynamicKeysWorker)
  yield takeLatest(deleteCMSLanguageStart.type, deleteCMSLanguageWorker)
  yield takeLatest(updateKycMethodStart.type, updateKycMethodWorker)
  yield takeLatest(updateCountryStatusStart.type, updateCountryStatusWorker)
  yield takeLatest(addReviewStart.type, addReviewWorker)
  yield takeLatest(updateReviewStart.type, updateReviewWorker)
  yield takeLatest(getReviewByIdStart.type, getReviewByIdWorker)
  yield takeLatest(getAllReviewStart.type, getAllReviewWorker)
  yield takeLatest(getWalletDataStart.type, getWalletDataWorker)
  yield takeLatest(getKycLabelsStart.type, getKycLabelsWorker)
  yield takeLatest(updateKycLabelsStart.type, updateKycLabelsWorker)
  yield takeLatest(getTagsDataStart.type, getTagsDataWorker)
}

function * getCountriesWorker (action) {
  try {
    const { limit = '', pageNo = '', name = '', isActive = '', kycMethod = '' } = action && action.payload

    const { data } = yield getCountries({
      limit,
      pageNo,
      name,
      isActive,
      kycMethod
    })

    yield put(getCountriesSuccess(data?.data?.countries))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getCountriesFailure())
  }
}

function * getAllAffiliatesWorker (action) {
  try {
    const { limit, pageNo, tenantId, search } = action && action.payload

    const { data } = yield getAllAffiliates({
      limit,
      pageNo,
      tenantId,
      search
    })

    yield put(getAllAffiliatesSuccess(data?.data?.affiliates))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllAffiliatesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAffiliateByIdWorker (action) {
  try {
    const { affiliateId } = action && action?.payload

    const { data } = yield getAffiliateById({
      affiliateId
    })

    yield put(getAffiliateByIdSuccess(data?.data?.affiliate))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAffiliateByIdFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * cmsWorker (action) {
  try {
    const { limit, pageNo, tenantId, search, adminId, isActive } = action && action.payload

    const { data } = yield getAllCms({
      limit,
      pageNo,
      tenantId,
      search,
      adminId,
      isActive
    })

    yield put(getAllCmsSuccess(data?.data?.cmsPages))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllCmsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCmsByPageIdWorker (action) {
  try {
    const { cmsPageId } = action && action?.payload

    const { data } = yield getCmsByPageId({
      cmsPageId
    })

    yield put(getCmsByPageIdSuccess(data?.data?.cmsDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getCmsByPageIdFailure(e?.response?.data?.errors[0].description))
  }
}

function * getAllUsersWorker (action) {
  try {
    const {
      limit, pageNo, tenantId, search,
      adminId, kycStatus, affiliateSearch,
      dobStart, dobEnd, userId,
      orderBy = '', sortBy = '',
      registrationDateStart,
      registrationDateEnd, isTenant,
      isInternal, country = '', ftdTo, ftdFrom, usersType, restrictedPlayers = ''
    } = action && action.payload

    const { data } = yield getAllUsers({
      limit,
      pageNo,
      tenantId,
      search,
      adminId,
      kycStatus,
      affiliateSearch,
      dobStart,
      dobEnd,
      userId,
      orderBy,
      sortBy,
      registrationDateStart,
      registrationDateEnd,
      isInternal,
      country,
      ftdTo,
      ftdFrom,
      usersType,
      restrictedPlayers,
      isTenant
    })

    yield put(getAllUsersSuccess(data?.data?.users))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllUsersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getUserWorker (action) {
  try {
    const { userId, isTenant } = action && action.payload

    const { data } = yield getUser({ userId, isTenant })

    yield put(getUserSuccess(data?.data?.getUser))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUserFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTenantCountriesWorker () {
  try {
    const { data } = yield getTenantCountries()

    yield put(getTenantCountriesSuccess(data?.data?.countries))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getTenantCountriesFailure())
  }
}

function * getRestrictedItemsWorker (action) {
  try {
    const { limit, pageNo, type, countryId, search = '' } = action && action.payload

    const { data } = yield getRestrictedItems({
      limit,
      pageNo,
      type,
      countryId,
      search
    })

    if (type === 'games') {
      yield put(getRestrictedItemsSuccess(data?.data?.restrictedItems?.games))
    } else {
      yield put(getRestrictedItemsSuccess(data?.data?.restrictedItems?.providers))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getRestrictedItemsFailure())
  }
}

function * getUnRestrictedItemsWorker (action) {
  try {
    const { limit, pageNo, type, countryId, search = '' } = action && action.payload

    const { data } = yield getUnRestrictedItems({
      limit,
      pageNo,
      type,
      countryId,
      search
    })

    if (type === 'games') {
      yield put(getUnRestrictedItemsSuccess(data?.data?.restrictedItems?.games))
    } else {
      yield put(getUnRestrictedItemsSuccess(data?.data?.restrictedItems?.providers))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUnRestrictedItemsFailure())
  }
}

function * addRestrictedItemsWorker (action) {
  try {
    const { games, type, countryId, navigate } = action && action.payload

    yield addRestrictedItems({
      itemIds: games,
      type,
      countryId
    })

    yield toast('Restricted Items Updated Successfully', 'success')

    yield put(addRestrictedItemsComplete())

    navigate(SuperAdminRoutes.Countries)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(addRestrictedItemsComplete())
  }
}

function * deleteRestrictedItemsWorker (action) {
  try {
    const { games, type, countryId, navigate } = action && action.payload

    yield deleteRestrictedItems({
      itemIds: games,
      type,
      countryId
    })

    yield toast('Restricted Items Deleted Successfully', 'success')

    yield put(deleteRestrictedItemsComplete())

    navigate(SuperAdminRoutes.Countries)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(deleteRestrictedItemsComplete())
  }
}

function * resetRestrictedItemsWorker () {
  yield put(resetRestrictedItemsComplete())
}

function * getRestrictedCountriesWorker (action) {
  try {
    const { limit, pageNo, type, itemId } = action && action.payload

    const { data } = yield getRestrictedCountries({
      limit,
      pageNo,
      type,
      itemId
    })

    yield put(getRestrictedCountriesSuccess(data?.data?.restrictedCountries))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getRestrictedCountriesFailure())
  }
}

function * getUnRestrictedCountriesWorker (action) {
  try {
    const { limit, pageNo, type, itemId, search } = action && action.payload

    const { data } = yield getUnRestrictedCountries({
      limit,
      pageNo,
      type,
      itemId,
      search
    })

    yield put(getUnRestrictedCountriesSuccess(data?.data?.unrestrictedCountries))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUnRestrictedCountriesFailure())
  }
}

function * addRestrictedCountriesWorker (action) {
  try {
    const { countries, type, itemId, navigate, game } = action && action.payload

    yield addRestrictedCountries({
      itemId,
      type,
      countryIds: countries
    })

    yield toast('Restricted Countries Updated Successfully', 'success')

    yield put(addRestrictedCountriesComplete())

    game
      ? navigate(SuperAdminRoutes.CasinoGames)
      : navigate(SuperAdminRoutes.CasinoProviders)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(addRestrictedCountriesComplete())
  }
}

function * deleteRestrictedCountriesWorker (action) {
  try {
    const { countries, type, itemId, navigate, game } = action && action.payload

    yield deleteRestrictedCountries({
      itemId,
      type,
      countryIds: countries
    })

    yield toast('Restricted Countries Deleted Successfully', 'success')

    yield put(deleteRestrictedCountriesComplete())

    game
      ? navigate(SuperAdminRoutes.CasinoGames)
      : navigate(SuperAdminRoutes.CasinoProviders)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(deleteRestrictedCountriesComplete())
  }
}

function * resetRestrictedCountriesWorker () {
  yield put(resetRestrictedCountriesComplete())
}

function * updateSuperAdminCMSWorker (action) {
  try {
    const { cmsData } = action && action.payload

    yield updateSuperAdminCMS(cmsData)

    yield toast('CMS edited', 'success')

    yield put(updateSuperAdminCMSComplete())
    yield put(getCmsByPageIdStart({ cmsPageId: cmsData?.cmsPageId }))
  } catch (e) {
    yield put(updateSuperAdminCMSComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getGlobalRegistartionWorker () {
  try {
    const { data } = yield getGlobalRegistration()

    yield put(getGlobalRegistrationCompleted(data?.data))
  } catch (e) {
    yield put(getGlobalRegistrationFailed())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateGlobalRegistartionWorker (action) {
  try {
    const { data } = action && action.payload

    yield updateGlobalRegistration(data)

    yield put(updateGlobalRegistrationCompleted())
    yield put(getGlobalRegistrationStart())
    yield toast('Registration Data Updated', 'success')
  } catch (e) {
    yield put(updateGlobalRegistrationFailed())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getUserDocumentWorker (action) {
  try {
    const { userId, isTenant } = action && action.payload

    const { data } = yield getUserDocument(userId, isTenant ? 'tenant' : 'superadmin')

    yield put(getUserDocumentSuccess(data?.data?.userDocument || data?.data))
  } catch (e) {
    yield put(getUserDocumentFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * verifyUserDocumentWorker (action) {
  try {
    const { data, userId, isTenant = false } = action && action.payload

    yield verifyUserDocument(data, isTenant)

    yield put(verifyUserDocumentComplete())

    yield put(getUserStart({ userId, isTenant }))

    yield toast('Document Verification Processed Successfully', 'success')

    yield put(getSAUserDocumentStart({ userId, isTenant }))
  } catch (e) {
    yield put(verifyUserDocumentComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateSACMSStatusWorker (action) {
  try {
    const { data, limit, pageNo, tenantId, adminId, search, isActive } = action && action.payload

    yield checkIsTenant() ? tenantViewToggleStatus(data) : superAdminViewToggleStatus(data)

    yield toast('CMS Status Updated Successfully', 'success')

    yield put(updateSACMSStatusComplete())
    yield put(getAllCmsStart({
      limit,
      pageNo,
      tenantId,
      adminId,
      search,
      isActive
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateSACMSStatusComplete())
  }
}

function * getCMSDynamicKeysWorker (action) {
  try {
    const { isTenant } = action && action.payload

    const { data } = yield getCMSDynamicKeys({ isTenant })

    yield put(getCMSDynamicKeysSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getCMSDynamicKeysFailure())
  }
}

function * deleteCMSLanguageWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield deleteCMSLanguage({ data, isTenant })

    yield put(deleteCMSLanguageComplete())
    yield toast('CMS Language Deleted Successfully', 'success')
    isTenant
      ? yield put(getCmsDetailsStart({ cmsId: data?.cmsPageId }))
      : yield put(getCmsByPageIdStart({ cmsPageId: data?.cmsPageId }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(deleteCMSLanguageComplete())
  }
}

function * updateKycMethodWorker (action) {
  try {
    const { data, setShowKycUpdateModal, kycMethod, limit, name, pageNo } = action && action.payload

    yield updateKycMethod({ data })

    yield put(updateKycMethodComplete())

    yield setShowKycUpdateModal(false)

    yield put(getCountriesStart({ limit, name, pageNo, isActive: '', kycMethod }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateKycMethodComplete())
  }
}

function * updateCountryStatusWorker (action) {
  try {
    const { data, limit, pageNo, isActive = '', name, kycMethod } = action && action.payload

    yield checkIsTenant() ? updateCountryStatus(data) : superAdminViewToggleStatus(data)

    yield toast('Country Status Updated Successfully', 'success')

    yield put(getCountriesStart({
      limit,
      pageNo,
      isActive,
      name,
      kycMethod
    }))
    yield put(updateCountryStatusComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateCountryStatusComplete())
  }
}

function * addReviewWorker (action) {
  try {
    const { data } = action && action.payload

    yield addReview({ data })

    yield put(addReviewSuccess())
  } catch (e) {
    yield put(addReviewFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getReviewByIdWorker (action) {
  try {
    const { reviewId } = action && action.payload

    const { data } = yield getUserDocument({ reviewId })

    yield put(getReviewByIdSuccess(data?.data))
  } catch (e) {
    yield put(getReviewByIdFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getAllReviewWorker (action) {
  try {
    const { limit, pageNo, status, tenantId, search, isTenant } = action && action.payload

    const { data } = yield getAllReviews({ limit, pageNo, status, tenantId, search, isTenant })

    yield put(getAllReviewSuccess(data?.data?.reviewDetails))
  } catch (e) {
    yield put(getAllReviewFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateReviewWorker (action) {
  try {
    const { data } = action && action.payload

    yield updateReview({ data })

    yield put(updateReviewSuccess())
  } catch (e) {
    yield put(updateReviewFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getWalletDataWorker (action) {
  try {
    const { userId, isTenant, tenantId } = action && action.payload

    const { data } = yield getWalletData({ userId, isTenant, tenantId })

    yield put(getWalletDataComplete(data?.data?.getWalletDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getWalletDataFailure())
  }
}

function * getKycLabelsWorker (action) {
  try {
    const { data } = yield getKycLabels(action.payload)
    yield put(getKycLabelsComplete(data?.data?.result?.list?.items))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getKycLabelsFailure())
  }
}

function * updateKycLabelsWorker (action) {
  try {
    const { isTenant, type, setOpen, setKycLabels, userId, setResetDocData, setResetModal } = action.payload
    const { data } = yield updateKycLabels(action.payload)
    const resultStatus = data?.data?.result?.status
    const resultOther = data?.data?.result?.other
    const resultOk = data?.data?.result?.ok

    if (resultStatus && !(resultStatus >= 200 && resultStatus < 300)) {
      yield toast(data?.data?.message, 'error')
    } else if (type === 'updateKycLabels' && resultOther) {
      isTenant ? yield put(updateTenantUserDetail(resultOther)) : yield put(updateKycLabelsComplete(resultOther))
      yield toast('Label Update Successfully', 'success')
      setOpen(false)
      setKycLabels('')
    } else if (type === 'resetKycLabels' && resultOk) {
      setResetDocData({})
      setResetModal(false)
      yield toast('Document Reset Successfully', 'success')
      const documentStartAction = isTenant ? getUserDocumentStart({ userId }) : getSAUserDocumentStart({ userId })
      yield put(documentStartAction)
    } else if (type === 'viewImage') {
      yield put(setBufferImage(data?.data?.result))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateKycLabelsFailure())
  }
}

function * getTagsDataWorker (action) {
  try {
    const { adminUserId = '' } = action && action?.payload
    const { data } = yield getTags({ adminUserId })
    yield put(getTagsDataComplete(data?.data?.tags?.value))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTagsDataComplete(null))
  }
}
