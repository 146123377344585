import React, { useEffect, useState } from 'react'
import MainRoute from './MainRoute'
import { tenantLoginStart } from '../../store/redux-slices/login'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const HomePage = () => {
  const [tenantToken, setTenantToken] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    // Function to get query parameters from the URL
    const getQueryParams = () => {
      const urlParams = new URLSearchParams(window.location.search)
      return {
        token: urlParams.get('token'),
        adminUserId: urlParams.get('adminUserId')
      }
    }

    // Extract token and adminUserId from query parameters
    const { token, adminUserId } = getQueryParams()

    if (token && adminUserId) {
      setTenantToken(token)
      dispatch(tenantLoginStart({ token, adminUserId, adminTenant: true, navigate }))
    } else {
      setTenantToken('token or adminUserId not found')
    }
  }, [])

  return (
    <div>{tenantToken && <MainRoute />}</div>
  )
}

export default HomePage
