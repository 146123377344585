import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllSuperAdminTransactionsStart,
  getAllSuperAdminTransactionsSuccess,
  getAllSuperAdminTransactionsFailure,
  getSuperAdminCasinoTransactionsStart,
  getSuperAdminCasinoTransactionsSuccess,
  getSuperAdminCasinoTransactionsFailure,
  updateSuperadminTransactionStatusStart,
  updateSuperadminTransactionStatusSuccess,
  updateSuperadminTransactionStatusFailure,
  getSuperAdminAllTransactionsStart,
  getSuperAdminAllTransactionsSuccess,
  getSuperAdminAllTransactionsFailure,
  getBannedGamesDetailsStart,
  getBannedGamesDetailsSuccess,
  getBannedGamesDetailsFailure,
  getAllBannedGamesStart,
  getAllBannedGamesSuccess,
  getAllBannedGamesFailure,
  createBannedGamesStart,
  createBannedGamesSuccess,
  createBannedGamesFailure,
  getBannedGamesListDetailStart,
  getBannedGamesListDetailSuccess,
  getBannedGamesListDetailFailure,
  updateBannedGamesStart,
  updateBannedGamesSuccess,
  updateBannedGamesFailure,
  removeBannedGamesStart,
  removeBannedGamesFailure,
  removeBannedGamesSuccess,
  setRemoveBannedGames,
  setRemoveBannedGameList,
  auditLogsStart,
  auditLogsSuccess,
  auditLogsFailure,
  userSessionLogsStart,
  userSessionLogsSuccess,
  userSessionLogsFailure,
  bannedGamesTogglerStart,
  bannedGamesTogglerSuccess,
  bannedGamesTogglerFailure
} from '../redux-slices/superAdminTransactions'
import {
  auditLogsDetails,
  bannedGamesToggler,
  createBannedGames,
  getAllBannedGames,
  getAllBannedGamesListDetail,
  getAllSuperAdminTransactions,
  getBannedGamesDetails,
  getSuperAdminAllTransactions,
  getSuperAdminCasinoTransactions,
  removeBannedGames,
  updateBannedGames,
  updateTransactionStatus,
  userSessionLogs
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import { getAllTenantTransactionsStart } from '../redux-slices/tenantTransactions'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { checkIsTenant } from '../../utils/constants'

export default function * SuperAdminTransactionsWatcher () {
  yield takeLatest(getAllSuperAdminTransactionsStart.type, getAllSuperAdminTransactionsWorker)
  yield takeLatest(getSuperAdminCasinoTransactionsStart.type, getSuperAdminCasinoTransactionsWorker)
  yield takeLatest(updateSuperadminTransactionStatusStart.type, updateSuperadminTransactionStatusWorker)
  yield takeLatest(getSuperAdminAllTransactionsStart.type, getSuperAdminAllTransactionsWorker)
  yield takeLatest(getBannedGamesDetailsStart.type, getBannedGamesDetailsWorker)
  yield takeLatest(getAllBannedGamesStart.type, getAllBannedGamesWorker)
  yield takeLatest(createBannedGamesStart.type, createBannedGamesWorker)
  yield takeLatest(updateBannedGamesStart.type, updateBannedGamesWorker)
  yield takeLatest(getBannedGamesListDetailStart.type, getBannedGamesListDetailWorker)
  yield takeLatest(bannedGamesTogglerStart.type, bannedGamesTogglerWorker)
  yield takeLatest(removeBannedGamesStart.type, removeBannedGamesWorker)
  yield takeLatest(auditLogsStart.type, auditLogsWorker)
  yield takeLatest(userSessionLogsStart.type, userSessionLogsWorker)
}

function * getAllSuperAdminTransactionsWorker (action) {
  try {
    const {
      limit, pageNo, search, startDate, endDate, currencyId, transactionType, tenantId, adminId, paymentProvider, isUserDetail,
      userId, status, kycStatus, countryCode, transactionId, createdAtStartDate, createdAtEndDate, paymentMethod, check, orderBy, sort, isTenant
    } =
      action && action.payload

    const { data } = yield getAllSuperAdminTransactions({
      limit,
      pageNo,
      search,
      startDate,
      endDate,
      currencyId,
      transactionType,
      tenantId,
      adminId,
      paymentProvider,
      isUserDetail,
      userId,
      status,
      kycStatus,
      countryCode,
      transactionId,
      createdAtEndDate,
      createdAtStartDate,
      paymentMethod,
      check,
      orderBy,
      sort,
      isTenant
    })

    yield put(getAllSuperAdminTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getAllSuperAdminTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminCasinoTransactionsWorker (action) {
  try {
    const {
      status,
      limit,
      pageNo,
      startDate,
      endDate,
      currencyCode,
      transactionType,
      tenantId,
      adminId,
      userId,
      orderBy,
      orderType,
      searchByUserId = '',
      amountType = '',
      gameSearch = '',
      isTenant = false,
      providers = '',
      showTotalAmount = false
    } = action && action.payload

    const { data } = yield getSuperAdminCasinoTransactions({
      limit,
      pageNo,
      startDate,
      endDate,
      currencyCode,
      transactionType,
      tenantId,
      status,
      adminId,
      userId,
      orderBy,
      orderType,
      searchByUserId,
      amountType,
      gameSearch,
      isTenant,
      providers,
      showTotalAmount
    })

    yield put(getSuperAdminCasinoTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getSuperAdminCasinoTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateSuperadminTransactionStatusWorker (action) {
  try {
    const {
      userId,
      transactionId,
      statusTransactionType,
      paymentTransactionId,
      statusAction,
      playerId,
      comment,
      isTenant,
      myTransactionId,
      date
    } = action && action.payload

    yield updateTransactionStatus({ userId, transactionId, paymentTransactionId, status: statusAction, transactionType: statusTransactionType, comment, isTenant, date })

    yield put(updateSuperadminTransactionStatusSuccess())
    yield toast('Record updated successfully', 'success')

    isTenant
      ? yield put(getAllTenantTransactionsStart({ ...action.payload, transactionId: myTransactionId, userId: playerId, isUserDetail: !!playerId }))
      : yield put(getAllSuperAdminTransactionsStart({ ...action.payload, transactionId: myTransactionId, userId: playerId, isUserDetail: !!playerId }))
  } catch (e) {
    yield put(updateSuperadminTransactionStatusFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminAllTransactionsWorker (action) {
  try {
    const { data } = yield getSuperAdminAllTransactions(action.payload)

    yield put(getSuperAdminAllTransactionsSuccess(data?.data?.allTransactions))
  } catch (e) {
    yield put(getSuperAdminAllTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getBannedGamesDetailsWorker (action) {
  try {
    const { data } = yield getBannedGamesDetails(action.payload)

    yield put(getBannedGamesDetailsSuccess(data?.data))
  } catch (e) {
    yield put(getBannedGamesDetailsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getAllBannedGamesWorker (action) {
  try {
    const { data } = yield getAllBannedGames(action.payload)

    yield put(getAllBannedGamesSuccess(data.data?.getBannedGames))
  } catch (e) {
    yield put(getAllBannedGamesFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * createBannedGamesWorker (action) {
  try {
    const { isTenant, gameData, navigate } = action.payload
    const { data } = yield createBannedGames(isTenant, gameData)
    yield put(createBannedGamesSuccess(data))
    yield toast('Record created successfully', 'success')
    navigate(isTenant ? TenantRoutes.BannedGames : SuperAdminRoutes.BannedGames)
  } catch (e) {
    yield put(createBannedGamesFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateBannedGamesWorker (action) {
  try {
    const { isTenant, gameData, navigate } = action.payload
    const { data } = yield updateBannedGames(isTenant, gameData)
    yield put(updateBannedGamesSuccess(data))
    if (gameData?.isClone) {
      yield toast('Record Created successfully', 'success')
      navigate(isTenant ? TenantRoutes.BannedGames : SuperAdminRoutes.BannedGames)
      yield put(setRemoveBannedGames([]))
      yield put(setRemoveBannedGameList([]))
    } else {
      yield toast('Record Update successfully', 'success')
      navigate(`/${isTenant ? 'tenant' : 'super-admin'}/banned-games/details/${gameData?.bannedGamesListId}`)
    }
  } catch (e) {
    yield put(updateBannedGamesFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getBannedGamesListDetailWorker (action) {
  try {
    const { data } = yield getAllBannedGamesListDetail(action.payload)

    yield put(getBannedGamesListDetailSuccess(data.data?.gameDetail))
  } catch (e) {
    yield put(getBannedGamesListDetailFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * removeBannedGamesWorker (action) {
  try {
    yield removeBannedGames(action.payload)
    yield put(removeBannedGamesSuccess())
    const { data } = yield getAllBannedGamesListDetail(action.payload)
    yield toast('Game removed successfully', 'success')
    yield put(getBannedGamesListDetailSuccess(data.data?.gameDetail))
  } catch (e) {
    yield put(removeBannedGamesFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * auditLogsWorker (action) {
  try {
    const { data } = yield auditLogsDetails(action.payload)
    yield put(auditLogsSuccess(data?.data?.userActionTrail))
  } catch (e) {
    yield put(auditLogsFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * bannedGamesTogglerWorker (action) {
  try {
    const { bannedGamesListId, name, isTenant, pageNo, search, limit, inActiveList } = action.payload
    const tenantPayloadData = { bannedGamesListId, name, isTenant, pageNo, search, limit, inActiveList }
    const { data } = yield bannedGamesToggler(checkIsTenant() ? tenantPayloadData : action.payload)
    yield put(bannedGamesTogglerSuccess(data?.data))
    yield toast('Record Updated successfully', 'success')
    yield put(getAllBannedGamesStart(action.payload))
  } catch (e) {
    yield put(bannedGamesTogglerFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * userSessionLogsWorker (action) {
  try {
    const { data } = yield userSessionLogs(action.payload)
    yield put(userSessionLogsSuccess(data?.data?.userSessionLogs))
  } catch (e) {
    yield put(userSessionLogsFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
