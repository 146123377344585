import React from 'react'
import useCasinoTransactionsList from '../hooks/useCasinoTransactionsList'
import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import DateRangePicker from '../../../../components/DateRangePicker'
import { getDateDaysAgo } from '../../../../utils/dateFormatter'
import { transactionType, statusType } from '../../CasinoTransactions/constants'
import CasinoTransactionsList from '../../../../components/CasinoTransactionList'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/pro-solid-svg-icons'
import { handleDownloadCSV } from '../../../../utils/fileDownloader'
import { AmountTypeFilter } from '../../../../components/FIlters'
import useCheckPermission from '../../../../utils/checkPermission'

const CasinoTransactions = ({ isTenant = false }) => {
  const {
    page,
    over,
    limit,
    state,
    sortBy,
    status,
    loading,
    setPage,
    setOver,
    setState,
    setLimit,
    userData,
    setSortBy,
    setStatus,
    providers,
    totalPages,
    amountType,
    gameSearch,
    bannedGames,
    allProviders,
    setProviders,
    setGameSearch,
    setAmountType,
    selectedAction,
    getCsvDownloadUrl,
    setSelectedAction,
    casinoTransactions,
    showBannedGameAmount,
    setShowBannedGameAmount
  } = useCasinoTransactionsList({ isTenant })

  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row className='search-section mt-5'>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Action Type
          </Form.Label>

          <Form.Select
            size='sm'
            disabled={showBannedGameAmount}
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setShowBannedGameAmount(false)
              setSelectedAction(e.target.value)
            }}
            value={selectedAction}
          >
            {transactionType && transactionType?.map(
              ({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              )
            )}
          </Form.Select>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Search Game
          </Form.Label>

          <Form.Control
            placeholder='Search Game'
            size='sm'
            style={{ width: '230px', marginRight: '10px', maxHeight: '15px' }}
            value={gameSearch}
            onChange={(event) =>
              setGameSearch(event.target.value)}
          />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Status
          </Form.Label>

          <Form.Select
            onChange={(e) => {
              setShowBannedGameAmount(false)
              setStatus(e.target.value)
            }}
            value={status}
            size='sm'
            disabled={showBannedGameAmount}
            style={{ maxWidth: '230px' }}
          >
            {statusType && statusType?.map(
              ({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              )
            )}
          </Form.Select>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Providers
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => setProviders(e.target.value)}
            value={providers}
          >
            <option value=''>Select Provider</option>
            {(isTenant ? allProviders?.rows : allProviders)?.map(
              ({ name, masterCasinoProviderId }) => (
                <option key={name} value={masterCasinoProviderId}>
                  {name}
                </option>
              )
            )}
          </Form.Select>
        </Col>`

        <Col xs='auto' className='d-flex mb-3'>
          <AmountTypeFilter amountType={amountType} setAmountType={setAmountType} />
        </Col>

        <Col style={{ maxHeight: '25px' }} xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Time Period
          </Form.Label>
          <DateRangePicker state={state} setState={setState} bonus transaction />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setSelectedAction('all')
                setLimit(15)
                setPage(1)
                setAmountType('')
                setProviders('')
                setState([
                  {
                    startDate: getDateDaysAgo(10),
                    endDate: new Date(),
                    key: 'selection'
                  }
                ])
                setStatus('all')
                setSortBy({
                  bonusAmount: 'desc',
                  cashAmount: 'desc',
                  Id: 'desc',
                  isBonusSorting: 2
                })
                setOver({
                  bonusAmount: false,
                  cashAmount: false,
                  Id: false
                })
                setShowBannedGameAmount(false)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Trigger message='Download as CSV'>
            <Button
              variant='outline-success'
              size='sm'
              hidden={isHidden({ module: { key: 'Transactions', value: 'DR' } })}
              disabled={casinoTransactions?.count === 0}
              onClick={() => {
                handleDownloadCSV(getCsvDownloadUrl(), `Casino_Transactions_${userData?.email}`)
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Banned Games
          </Form.Label>
          <Form.Switch
            name='bannedGames'
            checked={showBannedGameAmount}
            onChange={() => setShowBannedGameAmount(!showBannedGameAmount)}
          />
        </Col>

      </Row>
      <CasinoTransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={casinoTransactions}
        isTenant={false}
        userDetail
        sortBy={sortBy}
        setSortBy={setSortBy}
        over={over}
        setOver={setOver}
        loading={loading}
        bannedGames={bannedGames}
        showBannedGameAmount={showBannedGameAmount}
        userData={userData}
      />
    </>
  )
}

export default CasinoTransactions
