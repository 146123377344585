import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import React from 'react'
import useTransactionBList from './hooks/useTransactionBList'
import DateRangePicker from '../../../components/DateRangePicker'
import { getDateDaysAgo } from '../../../utils/dateFormatter'
import TransactionsList from '../../../components/TransactionsList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/pro-solid-svg-icons'
import Trigger from '../../../components/OverlayTrigger'
import { statusType } from '../../Super-Admin/TransactionsBanking/constants'
import { handleDownloadCSV } from '../../../utils/fileDownloader'
import ActionTypeSelect from '../../../components/ActionTypeSelect'
import TransactionSelects from '../../../components/ActionTypeSelect/TransactionSelects'
import useCheckPermission from '../../../utils/checkPermission'

export default () => {
  const {
    setSelectedCurrency,
    setSearch,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedCurrency,
    selectedAction,
    search,
    state,
    setState,
    tenantTransactions,
    loading,
    selectedPaymentProvider,
    setSelectedPaymentProvider,
    getCsvDownloadUrl,
    tenantDetails,
    status,
    setStatus,
    selectedCountry,
    setSelectedCountry,
    countries,
    transactionPaymentCategories,
    transactionId,
    setTransactionId,
    myUserId,
    setMyUserId,
    createdAt,
    setCreatedAt,
    paymentMethod,
    transactionReportingPaymentCategories,
    setPaymentMethod,
    check,
    setCheck,
    transactionsSortBy,
    setTransactionsSortBy,
    transactionHover,
    setTransactionHover
  } = useTransactionBList({ isUserDetail: false })

  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col sm={8}>
          <h3>Transactions Banking</h3>
        </Col>
      </Row>

      <Row className='mt-3 mb-3'>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Currency
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => setSelectedCurrency(e.target.value)}
            value={selectedCurrency}
          >
            <option value=''>Select a Currency</option>
            {tenantDetails &&
              tenantDetails?.tenantConfig?.allowedCurrencies?.map((code) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
          </Form.Select>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Country
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => setSelectedCountry(e.target.value)}
            value={selectedCountry}
          >
            <option value=''>Select a Country</option>
            {countries && countries?.rows?.map(
              ({ name, countryId, code }) => (
                <option key={countryId} value={code}>
                  {name}
                </option>
              )
            )}
          </Form.Select>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Action Type
          </Form.Label>

          <ActionTypeSelect selectedAction={selectedAction} setSelectedAction={setSelectedAction} />
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Actionee Type
          </Form.Label>

          <Form.Select
            type='select'
            size='sm'
            style={{ maxWidth: '200px' }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          >
            <option value='' key=''>
              All
            </option>
            <option value='admin' key='admin'>
              Admin
            </option>
            <option value='user' key='user'>
              User
            </option>
          </Form.Select>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Status
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            value={status}
            onChange={e =>
              setStatus(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
          >
            {statusType.map(({ label, value }) => {
              return <option key={label} value={value}>{label}</option>
            })}
          </Form.Select>
        </Col>

        <Col style={{ maxHeight: '25px' }} xs='auto' className='d-flex mb-3 align-items-center'>
          <Form.Label style={{ width: 'max-content' }} column='sm' className='text-nowrap px-2'>
            Created At
          </Form.Label>
          <Form.Check
            name='check1'
            className='px-2'
            value={check.createdAt}
            checked={check.createdAt}
            onChange={() => setCheck({ ...check, createdAt: !check.createdAt })}
          />
          <DateRangePicker
            state={createdAt} setState={setCreatedAt}
            player
            disabled={!check.createdAt}
          />
        </Col>

        <Col style={{ maxHeight: '25px' }} xs='auto' className='d-flex mb-3 align-items-center'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Updated At
          </Form.Label>
          <Form.Check
            name='check2'
            className='px-2'
            value={check.updatedAt}
            checked={check.updatedAt}
            onChange={() => setCheck({ ...check, updatedAt: !check.updatedAt })}
          />
          <DateRangePicker
            state={state} setState={setState} size='sm' player
            disabled={!check.updatedAt}
          />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Final PSP
          </Form.Label>

          <TransactionSelects
            transactionReportingPaymentCategories={transactionPaymentCategories}
            paymentMethod={selectedPaymentProvider}
            setPaymentMethod={setSelectedPaymentProvider}
            placeholder='Payment Provider'
          />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Initial PSP
          </Form.Label>
          <TransactionSelects
            transactionReportingPaymentCategories={transactionReportingPaymentCategories}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            placeholder='Payment Method'
          />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Transaction Id
          </Form.Label>

          <Form.Control
            size='sm'
            placeholder='Search By Transaction ID'
            style={{ maxWidth: '230px' }}
            onChange={(e) => setTransactionId(e.target.value)}
            value={transactionId}
          />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            User Id
          </Form.Label>

          <Form.Control
            size='sm'
            placeholder='Search By User ID'
            style={{ maxWidth: '230px' }}
            onChange={(e) => setMyUserId(e.target.value)}
            value={myUserId}
          />
        </Col>

        <Col style={{ maxHeight: '25px' }}>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setSearch('')
                setSelectedAction(JSON.stringify([]))
                setSelectedCurrency('')
                setLimit(15)
                setPage(1)
                setSelectedPaymentProvider('[]')
                setPaymentMethod('[]')
                setSelectedCountry('')
                setCheck({ createdAt: false, updatedAt: true })
                setState([
                  {
                    startDate: getDateDaysAgo(10),
                    endDate: new Date(),
                    key: 'selection'
                  }
                ])
                setCreatedAt([
                  {
                    startDate: getDateDaysAgo(10),
                    endDate: new Date(),
                    key: 'selection'
                  }
                ])
                setTransactionId('')
                setMyUserId('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
          <Trigger message='Download as CSV'>
            <Button
              variant='outline-success'
              size='sm'
              style={{ marginLeft: '10px' }}
              hidden={isHidden({ module: { key: 'Transactions', value: 'DR' } })}
              disabled={tenantTransactions?.count === 0}
              onClick={() => {
                handleDownloadCSV(getCsvDownloadUrl(), 'Transaction Banking')
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>
      </Row>
      <TransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={tenantTransactions}
        isTenant
        loading={loading}
        selectedCurrency={selectedCurrency}
        selectedAction={selectedAction}
        countries={countries?.rows}
        state={state}
        paymentProvider={selectedPaymentProvider}
        countryCode={selectedCountry}
        selectedStatus={status}
        actioneeType={search}
        myTransactionId={transactionId}
        playerId={myUserId}
        createdAt={createdAt}
        paymentMethod={paymentMethod}
        check={check}
        transactionsSortBy={transactionsSortBy}
        setTransactionsSortBy={setTransactionsSortBy}
        transactionHover={transactionHover}
        setTransactionHover={setTransactionHover}
      />
    </>
  )
}
