import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckSquare,
  faWindowClose,
  faEdit,
  faUserPlus,
  faEye,
  faAddressBook,
  faRightToBracket
} from '@fortawesome/pro-solid-svg-icons'
import { SuperAdminRoutes } from '../../../routes'
import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal from '../../../components/ConfirmationModal'
import Trigger from '../../../components/OverlayTrigger'
import useTenantOwner from './hooks/useTenantOwner'
import useCheckPermission from '../../../utils/checkPermission'
import { getLoginTenantDetailStart } from '../../../store/redux-slices/tenants'

export default () => {
  const {
    totalPages,
    limit,
    page,
    clientsFilterData,
    navigate,
    setLimit,
    setPage,
    setSearch,
    search,
    handleShow,
    show,
    setShow,
    handleYes,
    active,
    loading,
    name,
    status,
    setStatus,
    dispatch
  } = useTenantOwner()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col xs='auto'>
          <h3>Tenants Owner</h3>
        </Col>

        <Col className='d-flex justify-content-end align-items-center'>
          <Form.Label style={{ marginRight: '15px', marginTop: '8px' }}>
            Status
          </Form.Label>

          <Form.Select
            onChange={(e) => { setStatus(e.target.value) }}
            value={status}
            size='sm'
            style={{ maxWidth: '230px', width: 'auto', marginRight: '10px' }}
          >
            <option value=''>All</option>
            <option value='true'>Active</option>
            <option value='false'>In-Active</option>
          </Form.Select>

          <Form.Label style={{ marginRight: '15px', marginTop: '8px' }}>
            Search
          </Form.Label>
          <Form.Control
            type='search'
            placeholder='Search by Name or Email'
            value={search}
            size='sm'
            style={{ maxWidth: '230px', marginRight: '10px' }}
            onChange={(e) =>
              setSearch(e.target.value.replace(/[^\w\s@.+.-]/gi, ''))}
          />

          <Button
            variant='outline-success'
            size='sm'
            onClick={() => navigate(SuperAdminRoutes.CreateTenantAdmin)}
            hidden={isHidden({ module: { key: 'Tenant', value: 'C' } })}
          >
            Create
          </Button>
        </Col>
      </Row>
      {/* </div> */}

      {/* Button to create new tenant */}

      {/* Table with tenants info */}
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {['Id', 'Name', 'Email', 'Phone', 'Status', 'Action'].map((c) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(clientsFilterData) &&
              clientsFilterData?.rows?.map(
                ({
                  adminUserId,
                  firstName,
                  lastName,
                  email,
                  phone,
                  isActive
                }) => {
                  return (
                    <tr key={adminUserId}>
                      <td>{adminUserId}</td>

                      <Trigger message={`${firstName} ${lastName}`}>
                        <td>
                          <span
                            style={{
                              width: '150px',
                              cursor: 'pointer'
                            }}
                            className='d-inline-block text-truncate'
                          >{`${firstName} ${lastName}`}
                          </span>
                        </td>
                      </Trigger>

                      <td>
                        <Trigger message={email}>
                          <span
                            style={{
                              width: '200px',
                              cursor: 'pointer'
                            }}
                            className='text-link d-inline-block text-truncate'
                            onClick={() =>
                              navigate(
                                  `/super-admin/tenants/owners/details/${adminUserId}`
                              )}
                          >
                            {email}
                          </span>
                        </Trigger>
                      </td>
                      <td>{phone || 'NA'}</td>

                      <td>
                        {isActive
                          ? (
                            <span className='text-success'>Active</span>
                            )
                          : (
                            <span className='text-danger'>In Active</span>
                            )}
                      </td>
                      <td>
                        <ButtonGroup>
                          <Trigger message='Edit'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='warning'
                              onClick={() =>
                                navigate(
                                  `/super-admin/tenants/owners/edit/${adminUserId}`
                                )}
                              hidden={isHidden({ module: { key: 'Tenant', value: 'U' } })}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Trigger>

                          <Trigger message='View Tenants'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              onClick={() =>
                                navigate(`/super-admin/tenants/${adminUserId}`)}
                              hidden={isHidden({ module: { key: 'Tenant', value: 'R' } })}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </Trigger>

                          <Trigger message='View Managers'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='secondary'
                              onClick={() =>
                                navigate(`/super-admin/tenants/managers/${adminUserId}`)}
                              hidden={isHidden({ module: { key: 'Tenant', value: 'R' } })}
                            >
                              <FontAwesomeIcon icon={faAddressBook} />
                            </Button>
                          </Trigger>

                          <Trigger message='Create Tenant'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='success'
                              onClick={() =>
                                navigate(
                                  `/super-admin/tenants/create/${adminUserId}`
                                )}
                              hidden={isHidden({ module: { key: 'Tenant', value: 'C' } })}
                            >
                              <FontAwesomeIcon icon={faUserPlus} />
                            </Button>
                          </Trigger>

                          {!isActive
                            ? (
                              <Trigger message='Set Status Active'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='success'
                                  onClick={() =>
                                    handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                                  hidden={isHidden({ module: { key: 'Tenant', value: 'T' } })}
                                >
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </Button>
                              </Trigger>
                              )
                            : (
                              <Trigger message='Set Status In-Active'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  onClick={() =>
                                    handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                                  hidden={isHidden({ module: { key: 'Tenant', value: 'T' } })}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                              </Trigger>
                              )}

                          <Trigger message='Login Tenant'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='success'
                              onClick={() => dispatch(getLoginTenantDetailStart({ adminUserId }))}
                              hidden={isHidden({ module: { key: 'Tenant', value: 'C' } })}
                            >
                              <FontAwesomeIcon icon={faRightToBracket} />
                            </Button>
                          </Trigger>
                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                }
              )}

          {clientsFilterData?.count === 0 && !loading && (
            <tr>
              <td colSpan={6} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {clientsFilterData?.count !== 0 && !loading && (
        <PaginationComponent
          page={clientsFilterData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          name={name}
        />
      )}
    </>
  )
}
