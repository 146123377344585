import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Table, Button } from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../../components/Pagination'
import { setRemoveBannedGameList, setRemoveBannedGames } from '../../../../store/redux-slices/superAdminTransactions'
import Trigger from '../../../../components/OverlayTrigger'

const BannedGamesDetailsListing = ({
  pageNo,
  setPageNo,
  totalPages,
  limit,
  setLimit,
  getBannedGamesListDetail,
  actionButton,
  listBannedGames,
  setListBannedGames,
  loading
}) => {
  const { removeBannedGames, removedBannedGamesList } = useSelector((state) => state.superAdminTransactions)
  const dispatch = useDispatch()

  const rowHeader = ['Game Id', 'Name', 'Provider Name', 'RTP', 'status']
  if (actionButton) {
    rowHeader.push('action')
  }

  const removeGames = (id) => {
    const filteredGames = listBannedGames.filter(item => item.masterCasinoGameId !== id)
    const removedGames = listBannedGames.filter(item => item.masterCasinoGameId === id)
    dispatch(setRemoveBannedGames([...new Set([...removeBannedGames, ...removedGames])]))
    dispatch(setRemoveBannedGameList([...new Set([...removedBannedGamesList, ...filteredGames])]))
    setListBannedGames(filteredGames)
  }

  return (
    <>
      <Row>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4 scrollable'>
          <thead className='thead-dark'>
            <tr>
              {rowHeader.map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && listBannedGames.length > 0
              ? listBannedGames?.map(({
                masterCasinoGameId, name, returnToPlayer, MasterCasinoProvider, isActive, identifier
              }) => {
                return (
                  <tr key={`banned-games ${masterCasinoGameId}`}>
                    <td>{masterCasinoGameId}</td>
                    <td>{name}</td>
                    <td>{MasterCasinoProvider?.name || 'NA'}</td>
                    <td>{returnToPlayer || '-'} %</td>
                    <td
                      className={isActive ? 'text-success' : 'text-danger'}
                    >{isActive ? 'Active' : 'In-Active'}
                    </td>
                    {actionButton &&
                      <td>
                        <Trigger message='Remove Game'>
                          <Button
                            variant='danger'
                            size='sm'
                            onClick={() => {
                              removeGames(masterCasinoGameId)
                              // if (isClone) {
                              // }
                              // else {
                              //   dispatch(removeBannedGamesStart({
                              //     isTenant,
                              //     game: identifier,
                              //     bannedGamesListId,
                              //     adminId: '',
                              //     pageNo,
                              //     limit,
                              //     providerId: selectedProvider
                              //   }))
                              // }
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Trigger>
                      </td>}
                  </tr>
                )
              })
              : !loading && (
                <tr>
                  <td colSpan={7} className='text-danger text-center'>
                    No data found
                  </td>
                </tr>
                )}
          </tbody>
        </Table>
      </Row>
      {getBannedGamesListDetail && !loading && getBannedGamesListDetail?.count !== 0 && (
        <PaginationComponent
          page={getBannedGamesListDetail?.count < pageNo ? setPageNo(1) : pageNo}
          totalPages={totalPages}
          setPage={setPageNo}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </>
  )
}

export default BannedGamesDetailsListing
