import ReactQuill from 'react-quill'
import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { providerCategory } from './constants'
import { updateProviderSchema } from './schema'
import { ErrorMessage, Form, Formik } from 'formik'
import { toast } from '../../../../components/Toast'
import { safeStringify } from '../../../../utils/helpers'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, Form as BForm } from '@themesberg/react-bootstrap'
import { languageCode } from '../../../Tenant/TenantConfigurations/Languages/constants'

const General = ({
  details,
  logoCheck,
  amtOptions,
  updateData,
  setLogoCheck,
  tenantLanguages,
  providerDetails,
  transactionPaymentCategories
}) => {
  const [selectedTab, setSelectedTab] = useState((providerDetails?.description && typeof providerDetails?.description === 'object' && Object.keys(providerDetails?.description)?.[0]) || 'EN')
  const [description, setDescription] = useState(providerDetails?.description || {})

  Object.filter = (obj, predicate) =>
    Object.keys(obj)
      .filter(key => predicate(obj[key]))
      .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {})

  const FeesFields = ({ handleBlur, handleChange, value, name = '' }) => {
    return (
      <>
        <Col>
          <BForm.Label>
            Fixed Fee Per Success TRX <span className='text-danger'>* </span>
          </BForm.Label>
          <BForm.Control
            type='number'
            name={name ? `${name}[successFee]` : 'feeSuccessTx'}
            disabled={details}
            placeholder={details ? '' : 'Enter Amount'}
            value={name ? value.successFee : value.feeSuccessTx}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <ErrorMessage
            component='div'
            name={name ? `${name}[successFee]` : 'feeSuccessTx'}
            className='text-danger'
          />
        </Col>

        <Col>
          <BForm.Label>
            Fixed Fee Per Declined TRX <span className='text-danger'>* </span>
          </BForm.Label>
          <BForm.Control
            type='number'
            name={name ? `${name}[declinedFee]` : 'feeDeclinedTx'}
            disabled={details}
            placeholder={details ? '' : 'Enter Amount'}
            value={name ? value.declinedFee : value.feeDeclinedTx}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <ErrorMessage
            component='div'
            name={name ? `${name}[declinedFee]` : 'feeDeclinedTx'}
            className='text-danger'
          />
        </Col>

        <Col>
          <BForm.Label>
            MDR % <span className='text-danger'>* </span>
          </BForm.Label>
          <BForm.Control
            type='number'
            name={name ? `${name}[mdr]` : 'mdr'}
            disabled={details}
            placeholder={details ? '' : 'Enter MDR%'}
            value={value.mdr}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <ErrorMessage
            component='div'
            name={name ? `${name}[mdr]` : 'mdr'}
            className='text-danger'
          />
        </Col>
      </>
    )
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          depositImage: null,
          withdrawImage: null,
          amountKeys: { ...amtOptions },
          text: providerDetails?.settings?.description || '',
          name: providerDetails?.displayName || '',
          category: providerDetails?.category || 'OTHER',
          kycDepositCount: providerDetails?.settings?.kycDepositCount || 0,
          mdr: providerDetails?.settings?.mdr || 0,
          feeSuccessTx: providerDetails?.settings?.feeSuccessTx || 0,
          feeDeclinedTx: providerDetails?.settings?.feeDeclinedTx || 0,
          hideDepositCount: providerDetails?.settings?.hideDepositCount || 0,
          reportName: providerDetails?.reportingName || '',
          methodProviders: providerDetails?.settings?.methodProviders || [],
          redirection: providerDetails?.settings?.redirection || 'direct_api',
          gateway: providerDetails?.gateway || '',
          merchantId: providerDetails?.merchantId || '',
          merchantKey: providerDetails?.merchantKey || '',
          closedLoop: providerDetails?.settings?.closedLoop || false
        }}
        validationSchema={updateProviderSchema(
          providerDetails?.settings?.depositImageUrl,
          providerDetails?.settings?.withdrawImageUrl,
          providerDetails?.supportsDeposit,
          providerDetails?.supportsWithdrawal,
          logoCheck,
          amtOptions,
          providerDetails?.settings?.gateway,
          providerDetails?.aggregator
        )}
        onSubmit={(formValues) => {
          if (!providerDetails?.settings?.gateway) delete formValues.gateway
          if (!formValues?.merchantId) delete formValues.merchantId
          if (!formValues?.merchantKey) delete formValues.merchantKey
          if (!providerDetails?.supportsWithdrawal) delete formValues.closedLoop
          updateData({
            data: {
              ...formValues,
              depositImage: formValues.depositImage || providerDetails?.settings?.depositImageUrl,
              withdrawImage: logoCheck === 'false' ? (formValues.withdrawImage || providerDetails?.settings?.withdrawImageUrl) : null,
              logoCheck,
              text: Object.filter(description, desc => desc?.replace(/<[^>]+>/g, '')?.length > 0),
              methodProviders: safeStringify(formValues.methodProviders)
            },
            country: false
          })
        }}
      >
        {({ errors, values, handleChange, handleSubmit, handleBlur, setFieldValue, setFieldError }) => (
          <Form className='align-items-center m-3 edit-payment-method'>

            <Row className='d-flex mt-5'>
              <Col>
                <BForm.Label>
                  Display Name
                </BForm.Label>
                <BForm.Control
                  type='text'
                  name='name'
                  disabled={details}
                  placeholder={details ? '' : 'Enter Display Name'}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='name'
                  className='text-danger'
                />
              </Col>

              <Col>
                <BForm.Label>
                  Reporting Name <span className='text-danger'>* </span>
                </BForm.Label>
                <BForm.Control
                  type='text'
                  name='reportName'
                  disabled={details}
                  placeholder={details ? '' : 'Enter Reporting Name'}
                  value={values.reportName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='reportName'
                  className='text-danger'
                />
              </Col>

              <Col>
                <BForm.Label>
                  Category
                </BForm.Label>
                <BForm.Select
                  type='select'
                  name='category'
                  disabled={details}
                  value={values.category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {Object.keys(providerCategory).map(categoryKey =>
                    <option value={categoryKey} key={categoryKey}>{providerCategory[categoryKey]}</option>)}
                </BForm.Select>
              </Col>

              {['paymentiq', 'impaya', 'nodapay']?.includes(providerDetails?.aggregator) &&
                <Col>
                  <BForm.Label>
                    Redirection
                  </BForm.Label>
                  <div className='d-flex align-items-center'>
                    {(details
                      ? values?.redirection === 'cashier_iframe'
                      : true) &&
                        <BForm.Check
                          type='radio'
                          className='mx-2'
                          name='redirection'
                          label='Cashier IFrame'
                          value='cashier_iframe'
                          disabled={details}
                          checked={values?.redirection === 'cashier_iframe'}
                          onChange={handleChange}
                        />}
                    {(details
                      ? values?.redirection === 'direct_api'
                      : true) &&
                        <BForm.Check
                          type='radio'
                          className='mx-2'
                          name='redirection'
                          label='Direct API'
                          value='direct_api'
                          disabled={details}
                          checked={values?.redirection === 'direct_api'}
                          onChange={handleChange}
                        />}
                  </div>
                </Col>}
            </Row>

            {values?.amountKeys && Object.keys(values?.amountKeys).length > 0 &&
              <Row className='mt-3'>
                <BForm.Label>
                  Allowed Amount Options <span className='text-danger'>* </span>
                </BForm.Label>
                {Object.keys(values?.amountKeys).map((key, index) => (
                  values.amountKeys?.[key] !== 'Other' &&
                    <Col key={index}>
                      <BForm.Control
                        type='number'
                        name={`amountKeys.${[key]}`}
                        disabled={details}
                        placeholder={details ? '' : 'Enter Amount Value'}
                        value={values.amountKeys?.[key]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <ErrorMessage
                        component='div'
                        name={`amountKeys.${[key]}`}
                        className='text-danger'
                      />
                    </Col>
                )
                )}
              </Row>}

            {['impaya']?.includes(providerDetails?.aggregator) &&
              <Row>
                <Col xs={3}>
                  <BForm.Label>
                    Merchant Id<span className='text-danger'>* </span>
                  </BForm.Label>
                  <BForm.Control
                    type='text'
                    name='merchantId'
                    disabled={details}
                    placeholder={details ? '' : 'Enter Merchant Id'}
                    value={values.merchantId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='merchantId'
                    className='text-danger'
                  />
                </Col>

                <Col>
                  <BForm.Label>
                    Merchant Key<span className='text-danger'>* </span>
                  </BForm.Label>
                  <BForm.Control
                    type='text'
                    name='merchantKey'
                    disabled={details}
                    placeholder={details ? '' : 'Enter Merchant Key'}
                    value={values.merchantKey}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='merchantKey'
                    className='text-danger'
                  />
                </Col>
              </Row>}

            {providerDetails?.settings?.gateway &&
              <Row>
                <Col>
                  <BForm.Label>
                    Gateway<span className='text-danger'>* </span>
                  </BForm.Label>
                  <BForm.Control
                    type='text'
                    name='gateway'
                    disabled={details}
                    placeholder={details ? '' : 'Enter Gateway'}
                    value={values.gateway}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='gateway'
                    className='text-danger'
                  />
                </Col>
              </Row>}

            <Row>
              <FeesFields handleBlur={handleBlur} handleChange={handleChange} value={values} />
            </Row>

            <Row>
              {providerDetails?.supportsDeposit &&
                <Col xs={6} className='w-100'>
                  <BForm.Label>
                    Deposit Logo <span className='text-danger'>* </span>
                  </BForm.Label>
                  <BForm.Control
                    type='file'
                    disabled={details}
                    name='depositImage'
                    onChange={(e) => setFieldValue('depositImage', e.target.files[0])}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='depositImage'
                    className='text-danger'
                  />

                  {!errors?.depositImage && (values?.depositImage || providerDetails?.settings?.depositImageUrl) &&
                    <Row className='text-center'>
                      <Col>
                        <img
                          alt='not found'
                          className='mt-2'
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                          src={
                            values?.depositImage
                              ? URL.createObjectURL(values?.depositImage)
                              : providerDetails?.settings?.depositImageUrl
                          }
                        />
                      </Col>
                    </Row>}
                </Col>}
              {providerDetails?.supportsWithdrawal &&
                <Col xs={6}>
                  <div className='d-flex'>
                    <BForm.Label>
                      Withdraw Logo <span className='text-danger'>* </span>
                    </BForm.Label>
                    {providerDetails?.supportsDeposit &&
                      <>
                        (<p>Same as Deposit : &nbsp;</p>
                        <BForm.Check
                          value={logoCheck}
                          disabled={details}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue('withdrawImage', null)
                              setLogoCheck('true')
                              setFieldError('withdrawImage', null)
                            } else setLogoCheck('false')
                          }}
                        />)
                      </>}
                  </div>

                  <BForm.Control
                    type='file'
                    name='withdrawImage'
                    disabled={logoCheck === 'true' || details}
                    onChange={(e) => setFieldValue('withdrawImage', e.target.files[0])}
                    onBlur={handleBlur}
                  />

                  {logoCheck === 'false' &&
                    <ErrorMessage
                      component='div'
                      name='withdrawImage'
                      className='text-danger'
                    />}

                  {!errors?.withdrawImage && logoCheck === 'false' && (values?.withdrawImage || providerDetails?.settings?.withdrawImageUrl) &&
                    <Row className='text-center'>
                      <Col>
                        <img
                          alt='not found'
                          className='mt-2'
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                          src={
                            values?.withdrawImage
                              ? URL.createObjectURL(values?.withdrawImage)
                              : providerDetails?.settings?.withdrawImageUrl
                          }
                        />
                      </Col>
                    </Row>}
                </Col>}
              {providerDetails?.supportsDeposit && (
                <>
                  {/* <Col xs='auto'>
                    <BForm.Label>
                      Deposit Count
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      name='depositCount'
                      disabled={details}
                      placeholder='Enter Deposit Count'
                      style={{ maxWidth: '200px', maxHeight: '200px' }}
                      value={values.depositCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='depositCount'
                      className='text-danger'
                    />
                  </Col> */}

                  <Col xs='auto'>
                    <BForm.Label>
                      KYC Deposit Count
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      name='kycDepositCount'
                      disabled={details}
                      placeholder='Enter Deposit Count'
                      style={{ maxWidth: '200px', maxHeight: '200px' }}
                      value={values.kycDepositCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='kycDepositCount'
                      className='text-danger'
                    />
                  </Col>

                  <Col xs='auto'>
                    <BForm.Label>
                      Deposit Count to Hide Method
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      name='hideDepositCount'
                      disabled={details}
                      placeholder='Enter Deposit Count to Hide Method'
                      style={{ maxWidth: '200px', maxHeight: '200px' }}
                      value={values.hideDepositCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name='hideDepositCount'
                      className='text-danger'
                    />
                  </Col>

                  {providerDetails?.supportsWithdrawal &&
                    <Col xs='auto'>
                      <BForm.Label>
                        Closed Loop <span className='text-danger'>*</span>
                      </BForm.Label>
                      <BForm.Check
                        type='checkbox'
                        name='closedLoop'
                        disabled={details}
                        defaultChecked={values.closedLoop}
                        value={values.closedLoop}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>}
                </>
              )}
            </Row>

            {/* Description */}

            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              className='nav-light mt-3'
            >
              {(details ? description?.EN : true) &&
                <Tab
                  eventKey='EN'
                  title={
                    <Trigger message='English'>
                      <span>EN</span>
                    </Trigger>
                  }
                  mountOnEnter
                  tabClassName={selectedTab !== 'EN' ? (description?.EN !== undefined && description?.EN !== '') ? 'email' : '' : 'email-active'}
                >
                  <div className='mt-2'>
                    <label>
                      Description
                    </label>
                    <ReactQuill
                      name='description'
                      placeholder={details ? '' : 'Enter Description'}
                      preserveWhitespace
                      readOnly={details}
                      value={description?.EN || ''}
                      onChange={(e) => {
                        setDescription({
                          ...description,
                          EN: e
                        })
                      }}
                    />
                  </div>
                </Tab>}

              {tenantLanguages?.map(lang => (
                lang !== 'EN' && (details ? description?.[lang] : true) &&
                  <Tab
                    eventKey={lang}
                    key={lang}
                    title={
                      <Trigger message={languageCode[lang]}>
                        <span>{lang}</span>
                      </Trigger>
                  }
                    mountOnEnter
                    tabClassName={selectedTab !== lang ? (description?.[lang] !== undefined && description?.[lang] !== '') ? 'email' : '' : 'email-active'}
                  >
                    <div className='mt-2'>
                      <label>
                        Description
                      </label>
                      <ReactQuill
                        name='description'
                        placeholder={details ? '' : 'Enter Description'}
                        readOnly={details}
                        value={description?.[lang] || ''}
                        preserveWhitespace
                        onChange={(e) => {
                          setDescription({
                            ...description,
                            [lang]: e
                          })
                        }}
                      />
                    </div>
                  </Tab>
              ))}
            </Tabs>

            <Row className='mt-3 justify-content-end'>
              <Col xs={2}>
                <Button
                  hidden={details}
                  className='w-100'
                  variant='outline-success'
                  onClick={() => {
                    if (values.methodProviders.length && values.methodProviders?.find(({ name }) => name === '')) toast('Please fill all fields to add new providers', 'error')
                    else setFieldValue('methodProviders', [...values.methodProviders, { successFee: 0, declinedFee: 0, mdr: 0, name: '' }])
                  }}
                >
                  Add Providers
                </Button>
              </Col>
            </Row>

            {values?.methodProviders.length > 0 && values?.methodProviders.map((provider, index) => {
              return (
                <Row key={index} className='add-providers'>
                  <Col>
                    <BForm.Label>
                      Provider <span className='text-danger'>* </span>
                    </BForm.Label>
                    <BForm.Select
                      disabled={details || providerDetails?.settings?.methodProviders?.filter(({ name }) => name === provider?.name)?.length}
                      onChange={(e) => {
                        const providerValue = values.methodProviders.find(({ name }) => name === provider?.name)
                        providerValue.name = e.target.value
                        setFieldValue('methodProviders', [...values.methodProviders])
                      }}
                      value={values.methodProviders[index]?.name}
                    >
                      <option value='' disabled>---Select Provider---</option>
                      {transactionPaymentCategories && transactionPaymentCategories?.map(
                        (name) => name !== null && (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        )
                      )}
                    </BForm.Select>

                    <ErrorMessage
                      component='div'
                      name={`methodProviders[${index}][name]`}
                      className='text-danger'
                    />

                  </Col>

                  <FeesFields handleBlur={handleBlur} handleChange={handleChange} value={values.methodProviders[index]} name={`methodProviders[${index}]`} />

                  <Col xs='auto' className='align-self-end'>
                    <Button
                      hidden={details || providerDetails?.settings?.methodProviders?.filter(({ name }) => name === provider?.name)?.length}
                      className='w-100'
                      variant='outline-danger'
                      onClick={() => {
                        setFieldValue('methodProviders', values.methodProviders.filter(({ name }) => name !== provider?.name))
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Col>
                </Row>
              )
            })}

            <div
              className='mt-4 d-flex justify-content-end align-items-center'
            >
              <div>
                <Button
                  variant='outline-success'
                  onClick={() => {
                    handleSubmit()
                  }}
                  hidden={details}
                  className='ml-2'
                >
                  Submit
                </Button>
              </div>
            </div>

          </Form>
        )}
      </Formik>
    </>
  )
}

export default General
