import React from 'react'
import { Route } from 'react-router-dom'

import PrivateRoute from '../../../components/PrivateRoute'
import { TenantRoutes } from '../../../routes'
import Cms from '../../Super-Admin/CMS'
import Admin from '../../Tenant/Admins'
import CreateAdminUser from '../../Tenant/Admins/components/CreateAdminUser'
import TenantCredentials from '../../Tenant/TenantCredentials'
import CreateCredentials from '../../Tenant/TenantCredentials/components/CreateCredentials'
import EditCredentials from '../../Tenant/TenantCredentials/components/EditCredentials'
import Users from '../../Super-Admin/Users'
import UserDetails from '../../Super-Admin/UserDetails'
import TenantConfigurations from '../../Tenant/TenantConfigurations'
import FormFields from '../../Super-Admin/FormFields'
import TenantThemeSettings from '../../Tenant/TenantThemeSettings'
import CreateCms from '../../Super-Admin/CMS/components/CreateCMSNew'
import CmsDetails from '../../Super-Admin/CMS/components/CmsDetail'
import EditCms from '../../Super-Admin/CMS/components/EditCms'
import CasinoCategory from '../../Tenant/CasinoCategory'
import CasinoSubCategory from '../../Tenant/CasinoSubCategory'
import AdminUserDetails from '../../Tenant/AdminUserDetails'
import EditAdminUser from '../../Tenant/Admins/components/EditAdminUser'
import Wallet from '../../../components/Sidebar/components/Wallet'
import TransactionsBanking from '../../Tenant/TransactionsBanking'
import CasinoGames from '../../Tenant/CasinoGames'
import AddSubCategoryGames from '../../Tenant/AddSubCategoryGames'
import ReorderSubCategory from '../../Tenant/CasinoSubCategory/components/ReorderSubCategory'
import ReorderCategory from '../../Tenant/CasinoCategory/components/ReorderCategory'
import CasinoTransactions from '../../Tenant/CasinoTransactions'
import BonusManagement from '../../Tenant/Bonus'
import BonusDetail from '../../Tenant/Bonus/BonusDetail'
import CreateBonus from '../../Tenant/Bonus/components/CreateBonus'
import EditBonus from '../../Tenant/Bonus/components/EditBonus'
import CloneBonus from '../../Tenant/Bonus/components/CloneBonus'
import WageringTemplate from '../../Tenant/WageringTemplate'
import EditWageringTemplate from '../../Tenant/WageringTemplate/components/EditWageringTemplate'
import WageringTepltateDetails from '../../Tenant/WageringTemplate/components/WageringTeplateDetails'
import CreateWageringTemplate from '../../Tenant/WageringTemplate/components/CreateWageringTemplate'
import LoyaltyPoints from '../../../components/LoyaltyPoints'
import EditEmailTemplate from '../../Tenant/EmailTemplate/editEmailTemplate'
import EmailTemplates from '../../Tenant/EmailTemplate'
import ProfilePage from '../../../components/ProfilePage'
import Languages from '../../Tenant/TenantConfigurations/Languages'
import ImageGallery from '../../Super-Admin/ImageGallery'
import BannerManagement from '../../Super-Admin/BannerManagement'
import GameReorder from '../../Tenant/GameReorder'
import LanguageManagement from '../../Tenant/Languages/LanguageManagement'
import LanguageWise from '../../../components/LanguageMgmt/components/LanguageWise'
import KeyWise from '../../../components/LanguageMgmt/components/KeyWise'
import AddLangauge from '../../../components/LanguageMgmt/components/AddLanguage'
// import CashierManagement from '../../Tenant/CashierManagement'
import ReorderBonus from '../../../components/ReorderBonus'
import ReviewManagement from '../../Tenant/ReviewManagement'
import BannedGames from '../../Super-Admin/BannedGames'
import CreateBannedGames from '../../Super-Admin/BannedGames/components/CreateBannedGames'
import EditBannedGames from '../../Super-Admin/BannedGames/components/EditBannedGames'
import BannedGamesDetails from '../../Super-Admin/BannedGames/components/BannedGamesDetails'
import GroupManagement from '../../../components/GroupManagement'
import CreateGroup from '../../../components/GroupManagement/components/CreateGroup'
import CustomPayment from '../../../pages/Super-Admin/CustomPayment'
import EditProvider from '../../Super-Admin/CustomPayment/components/EditProvider'
import ProviderReorder from '../../Super-Admin/CustomPayment/components/ProviderReorder'
import Tournaments from '../../../components/Tournaments'
import CreateTournament from '../../../components/Tournaments/components/CreateTournament'
import Dashboard from '../../Super-Admin/Dashboard'
import CasinoProviders from '../../Super-Admin/CasinoProviders'
import EditBanners from '../../Super-Admin/BannerManagement/components/EditBanners'
import RGModule from '../../Super-Admin/RGModule'
import SetRGThresholds from '../../Super-Admin/RGModule/SetThresholds'
import SetAMLThresholds from '../../Super-Admin/AMLModule/setThresholds'
import AmlPlayerListing from '../../Super-Admin/AMLModule/playerListing'
import RealityCheck from '../../../components/RealityCheck'
import Aggregators from '../../Super-Admin/Aggregators'
import AddKeys from '../../../components/LanguageMgmt/components/AddKeys'
import CreateCurrencies from '../../Tenant/TenantConfigurations/components/CreateCurrencies'
import Countries from '../../Super-Admin/Countries'

const TenantPages = () => (
  <>
    <Route
      path={TenantRoutes.Wallet}
      element={
        <PrivateRoute isTenantRoute>
          <Wallet />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Dashboard}
      element={
        <PrivateRoute isTenantRoute isWithoutCard>
          <Dashboard />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CMS}
      element={
        <PrivateRoute isTenantRoute module={{ CMS: 'R' }}>
          <Cms />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Admins}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Admins: 'R' }}>
          <Admin />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateAdminUser}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'C' }}>
          <CreateAdminUser />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ReviewManagement}
      element={
        <PrivateRoute isTenantRoute module={{ Reviews: 'R' }}>
          <ReviewManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.TenantThemeSettings}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <TenantThemeSettings />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Credentials}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>

          <TenantCredentials />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Languages}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'R' }}>
          <Languages />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateCredentials}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'C' }}>
          <CreateCredentials />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.UpdateCredentials}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'U' }}>
          <EditCredentials />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Users}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <Users isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.UserDetails}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Users: 'R' }}>
          <UserDetails isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.TenantConfigurations}
      element={
        <PrivateRoute isTenantRoute module={{ Currencies: 'R' }}>
          <TenantConfigurations />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.FormFields}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <FormFields />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateCMS}
      element={
        <PrivateRoute isTenantRoute module={{ CMS: 'C' }}>
          <CreateCms />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CmsDetails}
      element={
        <PrivateRoute isTenantRoute module={{ CMS: 'R' }}>
          <CmsDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditCms}
      element={
        <PrivateRoute isTenantRoute module={{ CMS: 'U' }}>
          <EditCms />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CasinoCategory}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <CasinoCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CasinoSubCategory}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <CasinoSubCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CasinoGames}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <CasinoGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AddSubCategoryGames}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'U' }}>
          <AddSubCategoryGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AdminUserDetails}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'R' }}>
          <AdminUserDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditAdminUser}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'U' }}>
          <EditAdminUser />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.TransactionsBanking}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TransactionsBanking />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.CasinoTransactions}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <CasinoTransactions />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ReorderSubCategory}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'U' }}>
          <ReorderSubCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ReorderCategory}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'U' }}>
          <ReorderCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Bonus}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'R' }}>
          <BonusManagement />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.BonusDetail}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Bonus: 'R' }}>
          <BonusDetail />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.CreateBonus}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'C' }}>
          <CreateBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.EditBonus}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <EditBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.CloneBonus}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'C' }}>
          <CloneBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.BannedGames}
      element={
        <PrivateRoute isTenantRoute module={{ BannedGames: 'R' }}>
          <BannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateBannedGames}
      element={
        <PrivateRoute isTenantRoute module={{ BannedGames: 'C' }}>
          <CreateBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditBannedGames}
      element={
        <PrivateRoute isTenantRoute module={{ BannedGames: 'U' }}>
          <EditBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ClonedBannedGames}
      element={
        <PrivateRoute isTenantRoute module={{ BannedGames: 'U' }}>
          <EditBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AddBannedGames}
      element={
        <PrivateRoute isTenantRoute module={{ BannedGames: 'U' }}>
          <CreateBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AddClonedBannedGames}
      element={
        <PrivateRoute isTenantRoute module={{ BannedGames: 'U' }}>
          <CreateBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.BannedGamesDetail}
      element={
        <PrivateRoute isTenantRoute module={{ BannedGames: 'R' }}>
          <BannedGamesDetails isGameListing />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.WageringTemplate}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ WageringTemplate: 'R' }}>
          <WageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.WageringEdit}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ WageringTemplate: 'U' }}>
          <EditWageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.WageringDetails}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ WageringTemplate: 'R' }}>
          <WageringTepltateDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateWagering}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ WageringTemplate: 'C' }}>
          <CreateWageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateEmailTemplate}
      element={
        <PrivateRoute isWithoutHeader isTenantRoute module={{ EmailTemplate: 'C' }}>
          <EditEmailTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.EmailTemplate}
      element={
        <PrivateRoute isTenantRoute module={{ EmailTemplate: 'R' }}>
          <EmailTemplates />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.EditEmailTemplate}
      element={
        <PrivateRoute isWithoutHeader isTenantRoute module={{ EmailTemplate: 'U' }}>
          <EditEmailTemplate isEdit />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Profile}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader>
          <ProfilePage isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Loyalty}
      element={
        <PrivateRoute isTenantRoute module={{ LoyaltyManagement: 'R' }}>
          <LoyaltyPoints isTenant tenantLoyalty />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ImageGallery}
      element={
        <PrivateRoute isTenantRoute module={{ ImageGallery: 'R' }}>
          <ImageGallery isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.GameReorder}
      element={
        <PrivateRoute isTenantRoute isWithoutCard module={{ CasinoManagement: 'U' }}>
          <GameReorder />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.BannerManagement}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <BannerManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditBanner}
      element={
        <PrivateRoute isTenantRoute>
          <EditBanners module={{ BannerManagement: 'U' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ViewBanner}
      element={
        <PrivateRoute isTenantRoute>
          <EditBanners module={{ CasinoManagement: 'R' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateBanner}
      element={
        <PrivateRoute isTenantRoute>
          <EditBanners module={{ BannerManagement: 'C' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.LanguageManagement}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'R' }}>
          <LanguageManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditSupportLanguage}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'U' }}>
          <LanguageWise />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditSupportLanguageKeys}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'U' }}>
          <KeyWise />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AddLanguage}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'U' }}>
          <AddLangauge isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AddSupportLanguageKeys}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'U' }}>
          <AddKeys isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.BonusReorder}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <ReorderBonus isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Groups}
      element={
        <PrivateRoute isTenantRoute module={{ GroupPermission: 'R' }}>
          <GroupManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateGroup}
      element={
        <PrivateRoute isTenantRoute module={{ GroupPermission: 'C' }}>
          <CreateGroup isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditGroup}
      element={
        <PrivateRoute isTenantRoute module={{ GroupPermission: 'U' }}>
          <CreateGroup isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ViewGroup}
      element={
        <PrivateRoute isTenantRoute module={{ GroupPermission: 'R' }}>
          <CreateGroup isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.PaymentAggregators}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'R' }}>
          <CustomPayment />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Tournaments}
      element={
        <PrivateRoute isTenantRoute module={{ Tournament: 'R' }}>
          <Tournaments />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditCustomProviders}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'U' }}>
          <EditProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateTournaments}
      element={
        <PrivateRoute isTenantRoute module={{ Tournament: 'C' }}>
          <CreateTournament />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ViewCustomProviders}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'R' }}>
          <EditProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditTournaments}
      element={
        <PrivateRoute isTenantRoute module={{ Tournament: 'U' }}>
          <CreateTournament type='edit' />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CashierReorder}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'U' }}>
          <ProviderReorder isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ViewTournament}
      element={
        <PrivateRoute isTenantRoute module={{ Tournament: 'R' }}>
          <CreateTournament type='view' />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CloneTournament}
      element={
        <PrivateRoute isTenantRoute module={{ Tournament: 'C' }}>
          <CreateTournament type='clone' />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CasinoAggregators}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'R' }}>
          <Aggregators />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CasinoProviders}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'R' }}>
          <CasinoProviders />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.GamblingPlayerListing}
      element={
        <PrivateRoute isTenantRoute module={{ RG: 'R' }}>
          <RGModule key='rg' />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.SetThresholds}
      element={
        <PrivateRoute isTenantRoute module={{ RG: 'U' }}>
          <SetRGThresholds />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.GamblingSEPlayerListing}
      element={
        <PrivateRoute isTenantRoute module={{ RG: 'R' }}>
          <RGModule key='rg-self' selfExcluded />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.SetAMLThresholds}
      element={
        <PrivateRoute isTenantRoute module={{ AML: 'U' }}>
          <SetAMLThresholds />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AMLPlayerListing}
      element={
        <PrivateRoute isTenantRoute module={{ AML: 'R' }}>
          <AmlPlayerListing />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.RealityCheck}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ RealityCheck: 'R' }}>
          <RealityCheck />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.TenantConfigCurrency}
      element={
        <PrivateRoute isTenantRoute module={{ Currencies: 'C' }}>
          <CreateCurrencies isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Countries}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <Countries />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ErrLanguageManagement}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'R' }}>
          <LanguageManagement errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditErrSupportLanguageKeys}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'U' }}>
          <KeyWise errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditErrSupportLanguage}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'U' }}>
          <LanguageWise errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AddErrLanguage}
      element={
        <PrivateRoute isTenantRoute module={{ CashierManagement: 'U' }}>
          <AddLangauge errorCodes />
        </PrivateRoute>
      }
    />
  </>
)

export default TenantPages
