import React from 'react'
import { Form, Formik } from 'formik'
import CountryForm from './CountryForm'
import { Button, Spinner } from '@themesberg/react-bootstrap'
import { spreadKeysWithValues } from '../../../../utils/helpers'

const Country = ({
  data,
  search,
  setData,
  details,
  setSearch,
  updateData,
  myCountries,
  depositCount,
  kycCountries,
  cashier = false,
  setDepositCount,
  providerDetails,
  loading = false,
  setKycCountries,
  recommendedDeposit,
  recommendedWithdraw,
  setRecommendedDeposit,
  setRecommendedWithdraw
}) => {
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          blockedRegions: providerDetails?.regions?.blocked || [],
          kycCountries: providerDetails?.kycCountries || [],
          recommendedDeposit: providerDetails?.recommendedDeposit || [],
          recommendedWithdraw: providerDetails?.recommendedWithdraw || [],
          images: {},
          removeDepositImages: [],
          removeWithdrawImages: [],
          depositCount: providerDetails?.settings?.depositCount || {}
        }}
        onSubmit={(formValues) => {
          cashier
            ? updateData({
              data: {
                blockedRegions: data,
                kycCountries,
                logoCheck: 'false',
                ...spreadKeysWithValues(formValues?.images),
                recommendedDeposit,
                recommendedWithdraw,
                removeDepositImages: formValues?.removeDepositImages,
                removeWithdrawImages: formValues?.removeWithdrawImages,
                depositCount
              }
            })
            : updateData({ data: { blockedRegions: data, logoCheck: 'false' }, country: true })
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form className='align-items-center'>
            <CountryForm
              data={data}
              search={search}
              values={values}
              setData={setData}
              details={details}
              cashier={cashier}
              setSearch={setSearch}
              myCountries={myCountries}
              kycCountries={kycCountries}
              depositCount={depositCount}
              setFieldValue={setFieldValue}
              providerDetails={providerDetails}
              setKycCountries={setKycCountries}
              setDepositCount={setDepositCount}
              recommendedDeposit={recommendedDeposit}
              recommendedWithdraw={recommendedWithdraw}
              setRecommendedDeposit={setRecommendedDeposit}
              setRecommendedWithdraw={setRecommendedWithdraw}
              supportsDeposit={providerDetails?.supportsDeposit}
              supportsWithdrawal={providerDetails?.supportsWithdrawal}
            />
            <div
              className='mt-4 d-flex justify-content-end align-items-center'
            >
              {!details &&
                <div>
                  <Button
                    variant='outline-success'
                    onClick={() => {
                      handleSubmit()
                    }}
                    disabled={loading}
                    className='ml-2'
                  >
                    Submit
                    {loading && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                </div>}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Country
