export const tableHeaders = [
  { label: 'ID', value: 'transactionBankingId' },
  { label: 'Transaction ID', value: 'paymentTransactionId' },
  { label: 'TxRef Id', value: 'txRefId' },
  { label: 'Actionee', value: 'actioneeEmail' },
  { label: 'Target ID', value: 'Target Id' },
  { label: 'Tenant', value: 'portalName' },
  { label: 'KYC Status', value: 'kycStatus' },
  { label: 'Country', value: 'countryCode' },
  { label: 'Aggregator', value: 'aggregator' },
  { label: 'Final PSP', value: 'paymentProvider' },
  { label: 'Initial PSP', value: 'paymentMethod' },
  { label: 'TRX Success', value: 'trxSuccess' },
  { label: 'TRX Decline', value: 'trxDecline' },
  { label: 'MDR%', value: 'mdr' },
  { label: 'PSP Fee', value: 'pspFee' },
  { label: 'Amount', value: 'amount' },
  { label: 'Amount in EUR', value: 'primaryAmount' },
  { label: 'Action', value: 'transactionType' },
  { label: 'Actionee', value: 'actioneeType' },
  { label: 'Status', value: 'status' },
  { label: 'Created At', value: 'createdAt' },
  { label: 'Updated At', value: 'updatedAt' },
  { label: 'ACTION', value: 'action' }
]

export const allTransactionTableHeaders = [
  { label: 'ID', value: 'transactionBankingId' },
  { label: 'Transaction ID', value: 'paymentTransactionId' },
  { label: 'Transaction Type', value: 'transactionType' },
  { label: 'Amount', value: 'amount' },
  { label: 'Status', value: 'status' },
  { label: 'Amount Type', value: 'amountType' },
  { label: 'Before Money', value: 'beforeBalance' },
  { label: 'After Money', value: 'afterBalance' },
  { label: 'Updated By', value: 'updatedBy' },
  { label: 'Updated At', value: 'updatedAt' }
]

export const transactionType = [
  { label: 'Deposit', value: 'deposit' },
  { label: 'Withdraw', value: 'withdraw' },
  { label: 'Add Money', value: 'addMoney' },
  { label: 'Remove Money', value: 'removeMoney' },
  { label: 'Manual Deposit', value: 'manualDeposit' },
  { label: 'Manual Withdrawal', value: 'manualWithdraw' },
  { label: 'Bonus Claimed', value: 'bonus' },
  { label: 'Bonus To Cash', value: 'bonusToCash' },
  { label: 'Bonus Forfiet', value: 'bonusForfeit' },
  { label: 'Bonus Expired', value: 'bonusExpired' },
  { label: 'Bonus Zeroed Out', value: 'bonusZeroedOut' },
  { label: 'Internal Deposit', value: 'depositInternal' },
  { label: 'Internal Add Money', value: 'addMoneyInternal' },
  { label: 'Internal Withdraw', value: 'withdrawInternal' },
  { label: 'Internal Remove Money', value: 'removeMoneyInternal' },
  { label: 'Internal Bonus', value: 'bonusInternal' },
  { label: 'Internal Bonus To Cash', value: 'bonusToCashInternal' },
  { label: 'Internal Bonus Forfiet', value: 'bonusForfeitInternal' },
  { label: 'Internal Bonus Expired', value: 'bonusExpiredInternal' },
  { label: 'Internal Bonus Zeroed Out', value: 'bonusZeroedOutInternal' },
  { label: 'Manual Internal Deposit', value: 'manualDepositInternal' },
  { label: 'Manual Internal Withdrawal', value: 'manualWithdrawInternal' },
  { label: 'Reversal', value: 'reversal' }
]

export const allTransactionType = [
  { label: 'Deposit', value: 'deposit' },
  { label: 'Withdraw', value: 'withdraw' },
  { label: 'Add Money', value: 'addMoney' },
  { label: 'Remove Money', value: 'removeMoney' },
  { label: 'Manual Deposit', value: 'manualDeposit' },
  { label: 'Manual Withdrawal', value: 'manualWithdraw' },
  { label: 'Bonus Claimed', value: 'bonus' },
  { label: 'Bonus To Cash', value: 'bonusToCash' },
  { label: 'Bonus Forfiet', value: 'bonusForfeit' },
  { label: 'Bonus Expired', value: 'bonusExpired' },
  { label: 'Bonus Zeroed Out', value: 'bonusZeroedOut' },
  { label: 'Internal Deposit', value: 'depositInternal' },
  { label: 'Internal Add Money', value: 'addMoneyInternal' },
  { label: 'Internal Withdraw', value: 'withdrawInternal' },
  { label: 'Internal Remove Money', value: 'removeMoneyInternal' },
  { label: 'Manual Internal Deposit', value: 'manualDepositInternal' },
  { label: 'Manual Internal Withdrawal', value: 'manualWithdrawInternal' },
  { label: 'Refunded', value: 'refund' },
  { label: 'Bet', value: 'bet' },
  { label: 'Win', value: 'win' },
  { label: 'Win Internal', value: 'winInternal' },
  { label: 'Bet Internal', value: 'betInternal' },
  { label: 'RollBack', value: 'rollback' },
  { label: 'Pre RollBack', value: 'rollbackbeforebetwin' },
  { label: 'Free Spin', value: 'freespins' },
  { label: 'Reversal', value: 'reversal' }
]

export const statusType = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 0 },
  { label: 'Complete', value: 1 },
  { label: 'Cancelled', value: 2 },
  { label: 'Failed', value: 3 },
  { label: 'Requested', value: 7 },
  { label: 'Refunded', value: 8 },
  { label: 'Chargeback', value: 9 }
]

export const kycStatusType = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Completed', value: 'completed' },
  { label: 'Re-Requested', value: 'ReRequested' }
]

export const walletType = ['Cash', 'Bonus']

const transactionStatusPending = [
  // { label: 'Chargeback', value: 9 },
  { label: 'Cancel', value: 2 },
  { label: 'Complete', value: 1 }
]

const transactionStatusCompleted = [
  { label: 'Chargeback', value: 9 },
  { label: 'Cancel', value: 2 },
  { label: 'Refund', value: 8 }
]

const transactionStatusCancle = [
  { label: 'Cancel', value: 2 }
]

const transactionStatusCancelled = [
  { label: 'Complete', value: 1 }
]

const transactionStatusChargeBack = [
  { label: 'Complete', value: 1 },
  { label: 'Cancel', value: 2 },
  { label: 'Refund', value: 8 }
]

const transactionStatusRefund = [
  { label: 'Complete', value: 1 },
  { label: 'Cancel', value: 2 },
  { label: 'Chargeback', value: 9 }
]

export const transStatus = {
  0: transactionStatusPending,
  1: { deposit: transactionStatusCompleted, withdraw: transactionStatusCancle },
  2: transactionStatusCancelled,
  3: transactionStatusCancelled,
  7: { withdraw: transactionStatusPending },
  8: { deposit: transactionStatusRefund },
  9: { deposit: transactionStatusChargeBack }
}
